const _ = require('lodash')
const invokeTrackEvent = require('./promoteAnalytics')
const thirdPartyAnalytics = require('thirdPartyAnalytics')
const {withActions} = require('carmi-host-extensions')
const {registerTagManagerListeners} = require('./register-tag-manager-listeners')

const name = 'AnalyticsAspect'

const defaultModel = {
    promoteAnalyticsAdapter: null,
    isAdapterInitialized: false,
    setIsGoogleAnalyticsInitialized: false,
    loadedScripts: {},
    isTagManagerReady: false,
    pendingEvents: []
}

const trackEvent = (analyticsProps, packageProps = {}, eventName, params, options, analyticsActions) => {
    const {promoteAnalyticsAdapter} = packageProps
    if (!promoteAnalyticsAdapter) {
        return new Promise(resolve => {
            packageProps.requireFn('promote-analytics-adapter', analyticsPackage => {
                packageProps.setAnalyticsAdapterPackage(analyticsPackage)
                resolve(invokeTrackEvent(analyticsProps, eventName, params, options, analyticsPackage, analyticsActions))
            })
        })
    }
    return Promise.resolve(invokeTrackEvent(analyticsProps, eventName, params, options, promoteAnalyticsAdapter, analyticsActions))
}

const getPagePath = (currentUrl, externalBaseUrl) => {
    const currentUrlString = _.isString(currentUrl) ? currentUrl : currentUrl.full || ''
    const baseUrl = externalBaseUrl.replace(/\/$/, '')
    return currentUrlString.replace(baseUrl, '')
}

const reportPageEvent = withActions((analyticsActions, analyticsProps = {}, packageProps) => {
    const {currentUrl = {}, lastPageViewed} = analyticsProps
    if (currentUrl.full === lastPageViewed) {
        return
    }
    const pageData = {
        pagePath: getPagePath(analyticsProps.currentUrl, analyticsProps.externalBaseUrl),
        pageTitle: analyticsProps.pageTitle,
        pageId: analyticsProps.getPageId(),
        pageNumber: analyticsProps.getPageNumber()
    }
    thirdPartyAnalytics.reportPageEvent(analyticsProps, pageData.pagePath)
    const isFirstVisit = !lastPageViewed
    trackEvent(analyticsProps, packageProps, 'PageView', pageData, {context: {isFirstVisit}}, analyticsActions)
    analyticsActions.setLastPageViewUrl(analyticsProps.currentUrl.full)
})

const initFacebookRemarketing = (analyticsProps, pixelId) => pixelId ? thirdPartyAnalytics.initFacebookRemarketingPixelId(analyticsProps, pixelId) : thirdPartyAnalytics.initFacebookRemarketingUserPixel(analyticsProps)
const initGoogleRemarketing = analyticsProps => thirdPartyAnalytics.initGoogleRemarketingPixel(analyticsProps)
const initYandexMetrika = analyticsProps => thirdPartyAnalytics.initYandexMetrika(analyticsProps)

const functionLibrary = {
    fireFacebookRemarketingPixel: (analyticsProps, eventName, eventData) => thirdPartyAnalytics.fireFacebookRemarketingPixel(analyticsProps, eventName, eventData),
    fireFacebookCustomEvent: (analyticsProps, eventType, eventName, data) => thirdPartyAnalytics.fireFacebookCustomEvent(analyticsProps, eventType, eventName, data),
    fireGoogleRemarketing: () => thirdPartyAnalytics.fireGoogleRemarketingPixel(),
    reportGoogleAnalytics: (analyticsProps, eventParams) => thirdPartyAnalytics.reportGoogleAnalytics(analyticsProps, eventParams),
    reportGoogleTagManager: (analyticsProps, eventParams) => thirdPartyAnalytics.reportGoogleTagManager(analyticsProps, eventParams),
    reportBiAnalytics: (analyticsProps, eventParams) => thirdPartyAnalytics.reportBiAnalytics(analyticsProps, eventParams),
    reportYandexPageHit: url => thirdPartyAnalytics.reportYandexPageHit(url),
    reportPageEvent,
    trackEvent: withActions((analyticsActions, analyticsProps, packageProps, eventName, params, options) =>
        trackEvent(analyticsProps, packageProps, eventName, params, options, analyticsActions))
}

function init({setLoadedScripts, setIsTagManagerReady}, {eventsManager, initialData: {analyticsProps, isInSSR}}) {
    if (isInSSR) {
        return
    }
    if (analyticsProps.isTagManagerEnabled) {
        registerTagManagerListeners(analyticsProps, eventsManager, setLoadedScripts, setIsTagManagerReady)
        return
    }
    initFacebookRemarketing(analyticsProps)
    initGoogleRemarketing(analyticsProps)
    initYandexMetrika(analyticsProps)
}

module.exports = {
    name,
    defaultModel,
    functionLibrary,
    init
}
