import {utils} from 'santa-components'

const {GROUP_TYPES, getMasterPageChildrenGroups} = utils.masterPageUtils

export const name = 'PinnedRootAspect'

export const MASTER_PAGE_GROUP_TYPES = GROUP_TYPES

export const functionLibrary = {
    getMasterPageGroups: (childrenLayout, childrenIds, isMobileView, shouldReorderDOM) => {
        // create new object as default layout in runtime cause they get dirty inside `getMasterPageChildrenGroups()`
        const layoutMap = childrenIds.reduce((result, id) => {
            result[id] = childrenLayout[id] || {}
            return result
        }, {})
        return getMasterPageChildrenGroups(childrenIds, layoutMap, isMobileView, shouldReorderDOM)
    }
}
